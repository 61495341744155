import React, { useEffect, useState } from "react";
import { Button, Input, InputGroup, Container, Modal, ModalBody } from "reactstrap";
import { Row, Col } from "reactstrap";
import CustomizedCheckbox from "../../Checkbox/Checkbox";
import { postRequestData } from '../../../reducers/Requests/actions'
import { setModal } from '../../../reducers/Home/actions'
import { validateEmail, validatePhone, nameRegex, phoneRegex, locationRegex } from '../../../utils/util'
import { formData } from '../../../utils/formData'
import { connect } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import "./styles.scss";

const ServiceModal = ({ isOpen, onClick, details, serviceType, setModal }) => {
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [location, setLocation] = useState('')
  const [description, setDescription] = useState('');
  const [file, setFile] = useState(null);
  const [gdpr, setGDPR] = useState(false)
  const [terms, setTerms] = useState(false)
  const [marketing, setMarketing] = useState(false)
  const [validate, setValidate] = useState(false)
  const [files, setFiles] = useState(null);
  const [fileName, setFileName] = useState([]);


  useEffect(() => {
    setName('')
    setPhone('')
    setEmail('')
    setLocation('')
    setDescription('')
    setFile(null)
    setTerms(false)
    setGDPR(false)
    setMarketing(false)
    setValidate(false)
  }, [])

  const onFileChange = (e) => {
    let currentFile = Object.entries(e);
    let fileArr = [];

    currentFile.map(file => {
      fileArr.push(`${file[1].name}`)
    })
    setFileName(fileArr);

    let newFiles = files ? [...files] : []
    newFiles.push(e)
    setFiles(newFiles)
  }

  const validateFields = () => {
    if (name.length === 0 || !nameRegex.test(name) || !phoneRegex.test(phone) || phone.length === 0 ||
      !validateEmail(email) || email.length === 0 || location.length === 0 || location.length > 35 ||
      !gdpr || !terms || !marketing) {
      return true
    } else return false
  }

  const validateInputs = (name, phone, email, location) => {
    if (!nameRegex.test(name) || name.length > 35 || name.length === 0) {
      return 'Невалидно име! Допустими символи: латиница и кирилица.'
    }
    if (!phoneRegex.test(phone) || phone.length > 25 || phone.length === 0) {
      return 'Невалиден телефонен номер! Допустими символи: само цифри.'
    }
    if (!validateEmail(email) || email.length > 254 || email.length === 0) {
      return 'Невалиден имейл адрес! Допустими символи: латиница, цифри, и специални символи.'
    }
    if (location.length > 35 || location.length === 0) {
      return 'Невалидна локация! Допустими символи до 35'
    }
    if (!gdpr) {
      return 'Липсва съгласие за обработка на лични данни.'
    }
    if (!terms) {
      return 'Липсва съгласие за правилата за ползване.'
    }
    if (!marketing) {
      return 'Липсва съгласие за правилата за маркетинг.'
    }
    return ''
  }

  return (
    <div className="modal-wrapper">
      <Modal
        className='service-modal'
        size="lg"
        isOpen={isOpen}>
        <div className="close-btn d-flex justify-content-end">
          <i
            className="close-btn"
            role="none"
            onClick={() => {
              setName('')
              setPhone('')
              setEmail('')
              setLocation('')
              setDescription('')
              setFile(null)
              setTerms(false)
              setGDPR(false)
              setMarketing(false)
              setValidate(false)
              onClick()
            }}>
            <CloseIcon />
          </i>
        </div>
        <ModalBody>
          <Row>
            <Col sm={12}>
              <h4 className="font-weight-bold no-decoration text-center mb-4">
                Попълнете формата и изпратете запитване
              </h4>
            </Col>
          </Row>

          <Row className='my-3 mx-auto'>
            <Col sm={12} lg={6}>
              <InputGroup className="input-group-custom mb-2 ">
                <Input
                  maxLength={35}
                  placeholder="Име и фамилия"
                  className="input-field py-3 px-3 border-radius box-shadow"
                  onChange={e => setName(e.target.value)}
                />
              </InputGroup>
            </Col>
            <Col sm={12} lg={6}>
              <InputGroup className="input-group-custom mb-2 ">
                <Input
                  maxLength={25}
                  type='number'
                  placeholder="Телефон"
                  className="input-field py-3 px-3 border-radius box-shadow"
                  onChange={e => setPhone(e.target.value)}
                />
              </InputGroup>
            </Col>
            <Col sm={12} lg={6}>
              <InputGroup className="input-group-custom mb-2 ">
                <Input
                  type='email'
                  maxLength={254}
                  placeholder="Имейл"
                  className="input-field py-3 px-3 border-radius box-shadow"
                  onChange={e => setEmail(e.target.value)}
                />
              </InputGroup>
            </Col>
            <Col sm={12} lg={6}>
              <InputGroup className="input-group-custom mb-2 ">
                <Input
                  type='text'
                  maxLength={25}
                  placeholder="Локация"
                  className="input-field py-3 px-3 border-radius box-shadow"
                  onChange={e => setLocation(e.target.value)}
                />
              </InputGroup>
            </Col>
            <Col sm={12}>
              <Input
                className="project-input-textarea py-3 px-3 border-radius box-shadow"
                type="textarea"
                placeholder="Съобщение"
                onChange={(e) => setDescription(e.target.value)}
              />
            </Col>
          </Row>

          <div className='my-3'>
            <Row className="privacy-checkbox-row w-100">
              <div className='py-2 d-flex align-items-center'>
                <CustomizedCheckbox
                  checked={gdpr}
                  onChange={() => setGDPR(!gdpr)}
                />
                <label className="checkbox-label" htmlFor="privacy">
                  <p className='my-0'>
                    Съгласен съм личните ми данни да бъдат обработвани от "КСЕЛА България" ЕООД в съответствие с <a href='#' className='blue-link' onClick={() => window.open(`https://building-companion.bg/GDPR`, "_blank")}>политиката за защита на данни на КСЕЛА България</a>.
                  </p>
                </label>
              </div>
              <div className='py-2 d-flex align-items-center'>
                <CustomizedCheckbox
                  checked={terms}
                  onChange={() => setTerms(!terms)}
                />
                <label className="checkbox-label" htmlFor="privacy">
                  <p className='my-0'>
                    Съгласен съм и приемам <a href='#' className='blue-link' onClick={() => window.open(`https://building-companion.bg/terms-and-conditions?terms-and-conditions=0`, "_blank")}>правилата за ползване на уебсайта</a>.
                  </p>
                </label>
              </div>
              <div className='py-2 d-flex align-items-center'>
                <CustomizedCheckbox
                  checked={marketing}
                  onChange={() => setMarketing(!marketing)}
                />
                <label className="checkbox-label" htmlFor="privacy">
                  Съгласен съм личните ми данни да бъдат използвани за маркетингови цели.
                </label>
              </div>
            </Row>
          </div>
          <hr />
          <div>
            {
              validateFields() && validate &&
              <div className='data-alert mt-3 mx-auto px-2'>
                {validateInputs(name, phone, email, location)}
              </div>
            }
          </div>
          <div className="no-decoration text-center text-black-50">
            <div className="service-modal-footer-container">
              <h5 className="w-75 margin-0-auto my-3">
                Прикачи чертеж или друг документ
              </h5>
              <div className="d-flex input-file-container mb-3">
                <div className="upload-input d-flex align-items-center justify-content-between px-3">
                  <label htmlFor="" className="d-flex py-3 pr-1 m-0">
                    {fileName.length > 0 ? fileName.join(', ') : " Прикачете файл до 10MB"}
                  </label>
                  <label htmlFor="files" style={{ cursor: 'pointer' }} className="d-flex second-label m-0 py-2">
                    Изберете
                  </label>
                </div>
                <input
                  id="files"
                  style={{ visibility: "hidden", width: 0 }}
                  type="file"
                  multiple
                  onChange={e => {
                    onFileChange(e.target.files)
                  }}
                />
              </div>
            </div>
            <Container className="mt-3 btn-container d-flex justify-content-center">
              <Button
                className="box-shadow custom-btn w-50 py-3 text-uppercase mb-3"
                onClick={() => {
                  if (validateFields()) {
                    setValidate(true)
                    return
                  }
                  else {
                    onClick()
                  }
                  serviceType === 'architects' &&
                    formData.common(
                      formData.getOptions('POST', {
                        architect_id: Number(details.id),
                        full_name: name,
                        phone_number: phone,
                        email,
                        location,
                        description,
                        file,
                        gdpr_consent: gdpr,
                        terms_consent: terms,
                        marketing_consent: marketing
                      }), 'architects')
                      .then((res) => {
                        setModal()
                        setName('')
                        setPhone('')
                        setEmail('')
                        setLocation('')
                        setDescription('')
                        setFile(null)
                        setTerms(false)
                        setGDPR(false)
                        setMarketing(false)
                        setValidate(false)
                        onClick()
                      }).catch(() => setModal())
                  serviceType === 'dealers' &&
                    formData.common(
                      formData.getOptions('POST', {
                        dealer_id: Number(details.id),
                        full_name: name,
                        phone_number: phone,
                        email,
                        location,
                        description,
                        file,
                        gdpr_consent: gdpr,
                        terms_consent: terms,
                        marketing_consent: marketing
                      }),
                      'dealers')
                      .then(res => {
                        setModal()
                        setName('')
                        setPhone('')
                        setEmail('')
                        setLocation('')
                        setDescription('')
                        setFile(null)
                        setTerms(false)
                        setGDPR(false)
                        setMarketing(false)
                        setValidate(false)
                        onClick()
                      })
                      .catch(() => setModal())
                }}
              >
                Изпрати запитване
              </Button>
            </Container>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    serviceType: state.home.serviceType,
    details: state.services.details
  };
};

const mapDispatchToProps = {
  postRequestData,
  setModal
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceModal);
