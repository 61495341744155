import React, { useState, useEffect } from "react";
import { Row, Col, Button } from "reactstrap";
import Layout from "../../components/Layout";
import { navigate } from "gatsby";
import ServiceDescription from "../../components/ServiceDescription/ServiceDescription";
import ServiceActivityDescription from '../../components/ServiceActivityDescription/ServiceActivityDescription'
import { connect } from "react-redux";
import {
  getArchitectDetails,
  getBuildersDetails,
  getDealersDetails,
} from "../../reducers/Services/actions";
import { setModal } from '../../reducers/Home/actions'
import { postFieldCounter } from '../../reducers/Requests/actions'
import CarouselGallery from "../../components/CarouselGallery/CarouselGallery";
import ServiceModal from "../../components/Modals/ServiceModal/ServiceModal";
import ConfirmationModal from "../../components/Modals/ConfirmationModal/ConfirmationModal";
import AliceCarousel from "react-alice-carousel";
import defaultImage from '../../assets/images/xella-default-big.png';
import architectDefault1 from '../../assets/images/service-details/architect-details-1.jpg'
import architectDefault2 from '../../assets/images/service-details/architect-details-2.jpg'
import architectDefault3 from '../../assets/images/service-details/architect-details-3.jpg'
import builderDefault1 from '../../assets/images/service-details/builder-details-1.jpg'
import builderDefault2 from '../../assets/images/service-details/builder-details-2.jpg'
import builderDefault3 from '../../assets/images/service-details/builder-details-3.jpg'
import dealerDefault1 from '../../assets/images/service-details/dealer-details-1.jpg' 
import dealerDefault2 from '../../assets/images/service-details/dealer-details-2.jpg' 
import dealerDefault3 from '../../assets/images/service-details/dealer-details-3.jpg' 
import "react-alice-carousel/lib/alice-carousel.css";
import "./styles.scss";

const ServiceDetails = ({
  getArchitectDetails,
  details,
  getBuildersDetails,
  getDealersDetails,
  successModal,
  setModal,
  serviceType,
  postFieldCounter
}) => {
  const [isMobile, setIsMobile] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isPartnerModalOpen, setPartnerModalOpen] = useState(false);
  const [phoneFiled, onPhoneClick] = useState(false)
  const [emailFiled, onEmailClick] = useState(false)

  let url = "";
  let serviceId = "";
  let service = ""

  if (typeof window !== "undefined") {
    url = new URL(window.location.href);
    serviceId = url.searchParams.get("id");
    service = url.searchParams.get('service')
  }

  useEffect(() => {
    if (serviceId) {
      if (service === "architects") {
        getArchitectDetails({ id: serviceId });
        return;
      }
      if (service === "builders") {
        getBuildersDetails({ id: serviceId });
        return;
      }
      if (service === "dealers") {
        getDealersDetails({ id: serviceId });
        return;
      }
    }
  }, [getArchitectDetails, getBuildersDetails, getDealersDetails, service, serviceId]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 720) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const returnServiceName = (service) => {
    switch (service) {
      case 'architects':
        return 'архитект'
      case 'builders':
        return 'строител'
      case 'dealers':
        return 'дистрибутор'
      default: return ''
    }
  }

  const navigateToModal = (service) => {
    switch (service) {
      case "architects":
        setPartnerModalOpen(true);
        break;
      case "builders":
        navigate("/building-request");
        break;
      case "dealers":
        setPartnerModalOpen(true);
        break;
      default:
        break;
    }
  };

  const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    1024: { items: 3 },
    1320: { items: 5 }
  };

  const returnUrl = (url) => {
    return url ? (
      <span className='font-weight-bold'>
        {service !== "builders"
          ? 'Актуално състояние на дружеството в търговския регистър вижте '
          : 'Вписан в регистъра на КСБ '}
        <span
          role='none'
          className='blue-link cursor-pointer'
          onClick={() => window.open(url, "_blank")}
        >
          тук
        </span>.
      </span>
    ) : (
      service !== "builders"
        ? 'Не е вписан в търговския регистър.'
        : 'Не е вписан в регистъра на КСБ.'
    );
  };

  const returnPhone = (phone) => {
    if (phoneFiled) {
      return (
        <span>
          {phone}
        </span>
      )
    } else {
      return (
        <span
          role='none'
          className='blue-link cursor-pointer'
          onClick={() => {
              postFieldCounter({
                type: service,
                id: details.id,
                phone: true,
                email: false
              })
            onPhoneClick(!phoneFiled)
          }}
        >
          покажи
        </span>
      )
    }
  }

  const returnEmailLink = (email) => {
    if (emailFiled) {
      return (
        <div 
          className='blue-link cursor-pointer'
          onClick={() => navigateToModal(service)}
          >
            {email}
        </div>
      )
    } else {
      return (
        <span
          role='none'
          className='blue-link cursor-pointer'
          onClick={() => {
              postFieldCounter({
                type: service,
                id: details.id,
                phone: false,
                email: true
              })
            onEmailClick(!emailFiled)
          }}
        >
          покажи
        </span>
      )
    }
  }

  const returnWebUrl = (url) => {
    return url
      ? <> <span className='font-weight-bold'>
        Уебсайт:
      </span>
        <a href='#' className='blue-link ml-2' onClick={() => window.open(url, "_blank")}>{url}</a>
      </>
      : null
  }

  const renderServiceType = (data) => {
    switch (data) {
      case "architects":
        return "Архитектурно бюро";
      case "builders":
        return "Строителна компания";
      case "dealers":
        return "Дистрибутори";

      default:
        break;
    }
  };

  const returnCoverage = (data) => {
    let coverage = ''
    if (data.full_coverage) {
      return coverage = ` в цяла България`
    }
    data.districts && data.districts.forEach((d, i) => {
      if (i === data.districts.length - 1) return coverage = coverage + ` област ${d} `
      return coverage = coverage + ` област ${d}, `
    })
    data.cities && data.cities.forEach((d, i) => {
      if (i === data.districts.length - 1) return coverage = coverage + ` град ${d} `
      return coverage = coverage + ` град ${d}, `
    })
    return coverage
  }

  const fillWithDefaultImage = (details) => {
    if (details && details.other_images.length < 5) {
      let imagesNeeded = 5 - details.other_images.length;
      const newArr = [...details.other_images];

      for (let i = 0; i < imagesNeeded; i++) {
        newArr.push(defaultImage)
      }

      return newArr.map((image, i) => (
        (i < details.other_images.length
          ? <img
            alt=""
            key={i}
            src={`${image && process.env.GATSBY_XELLA_BACKEND}${image}`}
            className="details-carousel-img"
          />
          : <img
            alt=""
            key={i}
            src={defaultImage}
            className="details-carousel-img"
          />
        )
      ))
    }

    return details.other_images.map((image, i) => (
      <img
        alt=""
        key={i}
        src={`${image && process.env.GATSBY_XELLA_BACKEND}${image}`}
        className="details-carousel-img"
      />
    ));
  }

  const returnDefaultImages = () => {

    let defaultImg = []

    switch (service) {
      case "architects":
        defaultImg = [
          { image: architectDefault2 },
          { image: architectDefault3 },
          { image: architectDefault1 },
          // { image: defaultImage },
          // { image: defaultImage },
        ]
        break;
      case "builders":
        defaultImg = [
          { image: builderDefault2 },
          { image: builderDefault3 },
          { image: builderDefault1 },
          // { image: defaultImage },
        ]
        break;;
      case "dealers":
        defaultImg = [
          { image: dealerDefault1 },
          { image: dealerDefault2 },
          { image: dealerDefault3 },
          // { image: defaultImage },
          // { image: defaultImage },
        ]
        break;

      default:
        defaultImg = [
          { image: defaultImage },
          { image: defaultImage },
          { image: defaultImage },
          { image: defaultImage },
          { image: defaultImage },
        ]
        break;
    }

    return defaultImg.map((i, index) => (
      <img
        alt=""
        key={index}
        src={i.image}
        className="details-carousel-img"
      />
    ))
  }

  const returnSeoDescription = (service) => {
    switch (service) {
      case 'architects':
        return 'Намерете архитект/архитектурно бюро'
      case 'builders':
        return 'Намерете строителна фирма'
      case 'dealers':
        return 'Намерете дистрибутор'
      default: return ''
    }
  }

  const renderDescription = (service) => {
    switch(service) {
      case 'architects':
      return <ServiceActivityDescription
          desc1={`Обхват на дейността:`}
          desc12={'(Описание на извършваните проектантски услуги)'}
          value1={details && details.activities}
        />
      case 'builders': 
        return <ServiceDescription
        desc1="Специализация по дейности:"
        value1={details && details.activities}
      />   
      case 'dealers': 
      return <ServiceDescription
        desc1="Дейности:"
        value1={details && details.activities}
      />
      default: return null
    }
  }

  return (
    <Layout description={returnSeoDescription(service)}>
      <div className="service-details-wrapper">
        {
          // serviceType !== 'dealers' && service !== 'dealers'
            // ? 
            <div className="details-carousel">
              <AliceCarousel
                infinite
                responsive={responsive}
                dotsDisabled={true}
              >
                {
                  details && details.other_images.length > 0
                    ? fillWithDefaultImage(details)
                    : returnDefaultImages()
                }
              </AliceCarousel>
            </div>
            // : null
        }
        <div className="service-details-container">
          <div className="service-details-top-container">
            {
              isMobile
                ? <Row>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    {
                      service === 'dealers' || service === 'architects' || service === 'builders'
                        ? <a href={`/services?service=${service}`} className='blue-link'>
                          <p className="mt-4 all-promotions">назад</p>
                        </a>
                        : null
                    }

                    <Row className="mt-5">
                      <Col sm={8} md={6} lg={4}>
                        {details && details.logo && details.logo.length > 0
                          ? <img
                            alt=''
                            className='w-100'
                            src={`${process.env.GATSBY_XELLA_BACKEND}${details.logo}`} />
                          : null
                        }
                      </Col>
                    </Row>

                    <div className="services-info-container mt-3">
                      {renderServiceType(service)}
                    </div>
                    <h1 className='font-weight-bold'>{details && details.title}</h1>
                    {
                      service === 'dealers' || service === 'architects' || service === 'builders'
                        ?
                        <p className="text-black-50">
                          Предоставя услуги:
                          {details && returnCoverage(details)}
                        </p>
                        : null
                    }
                  </Col>
                </Row>
                : <Row className={serviceType === "dealers" || service === 'dealers' ? "mt-5 pt-3" : ""}>
                  <Col xs={6} sm={6} md={6} lg={8}>
                    <p
                      role='none'
                      className="blue-link cursor-pointer mt-4"
                      onClick={() => navigate(`/services?service=${service}`)}
                    >
                      назад
                    </p>

                    {details && details.logo && details.logo.length > 0
                      ?
                      <Row className="mt-5">
                        <Col sm={8} md={6} lg={4}>
                          <img
                            alt=''
                            className='w-100'
                            src={`${process.env.GATSBY_XELLA_BACKEND}${details.logo}`} />
                        </Col>
                      </Row>
                      : null
                    }

                    <div className="services-info-container mt-5">
                      {renderServiceType(service)}
                    </div>
                    <h1 className="mb-2 font-weight-bold">{details && details.title}</h1>
                    {
                      service !== 'dealers'
                        ?
                        <p className="text-black-50">
                          Предоставя услуги:
                          {details && returnCoverage(details)}
                        </p>
                        : null
                    }
                  </Col>
                  <Col
                    xs={6}
                    sm={6}
                    md={6}
                    lg={4}
                    className="d-flex align-self-center flex-end"
                  >
                  </Col>
                </Row>
            }
            <Row className="mt-3">
              <Col md={12} lg={12}>
                <p>{details && details.description}</p>
              </Col>
            </Row>
            <div className="service-details-tab">
              <h4 className="font-weight-bold service-details-tab-title">Описание</h4>
              <hr className="service-details-hr" />
              <div className="service-details-inner-tab py-2">
                <ServiceDescription
                  desc1={details && details.employees_count ? "Брой служители:" : null}
                  value1={details && details.employees_count}
                  desc2="За контакт:"
                  value2={details && details.contact_name}
                  desc3='ПК'
                  value3={details && details.zip_code}
                />
                <ServiceDescription
                  desc1={details && details.year_of_creation ? "Основана:" : null}
                  value1={details && details.year_of_creation}
                  desc2="Телефон:"
                  value2={details && returnPhone(details.phone)}
                  desc3='Населено място'
                  value3={details && details.place}
                />
                <ServiceDescription
                  desc1={details && details.certification ? "Категория обекти:" : null}
                  value1={details && details.certification}
                  desc2="Имейл:"
                  value2={details && returnEmailLink(details.email)}
                  desc3="Адрес:"
                  value3={details && details.address}
                />
                <ServiceDescription
                  desc1={details && details.address_details ? "Детайли за адреса:" : null}
                  value1={details && details.address_details}
                  desc2={''}
                />
              </div>
              <div className="service-details-inner-tab pt-5 pb-4 border-top">
                {renderDescription(service)}
                <ServiceDescription
                  desc1={details && details.approximate_deadline ? "Приблизителен срок за изпълнение:" : null}
                  value1={details && details.approximate_deadline}
                  desc2={''}
                />
                {
                  details && details.website_url &&
                  <div className="mb-3 pt-3">
                    {details && returnWebUrl(details.website_url)}
                  </div>
                }
              </div>
              <div className="service-details-inner-tab mb-3 pt-3 border-top">
                {details && returnUrl(details.registry_agency_url)}
              </div>
            </div>
            <Row>
              <Col className="d-flex justify-content-center">
                <Button
                  onClick={() => {
                    navigateToModal(service);
                  }}
                  className="px-5 py-3 my-5 justify-content-start service-details-btn shadow text-uppercase "
                >
                  Изпрати запитване
                </Button>
              </Col>
            </Row>
          </div>
        </div>
        <CarouselGallery
          isOpen={isModalOpen}
          onClick={() => {
            setModalOpen(!isModalOpen);
          }}
        />
      </div>
      <ServiceModal
        isOpen={isPartnerModalOpen}
        onClick={() => setPartnerModalOpen(!isPartnerModalOpen)}
        id={serviceId}
      />
      <ConfirmationModal
        title={`Изпратихте успешно запитване за ${returnServiceName(service) !== '' ? returnServiceName(service) : 'строителна компания'}.`}
        isOpen={successModal}
        onClick={() => setModal()}
      />
    </Layout >
  );
};

const mapStateToProps = (state) => {
  return {
    details: state.services.details,
    successModal: state.home.successModal,
    serviceType: state.home.serviceType
  };
};

const mapDispatchToProps = {
  getArchitectDetails,
  getBuildersDetails,
  getDealersDetails,
  setModal,
  postFieldCounter
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceDetails);
