import React from 'react';
import { Col, Row } from 'reactstrap';
import { Chip } from '@mui/material';
import './styles.scss';

const ServiceActivityDescription = (props) => {
  const returnSet = (data) => {
    return <div style={{ flexWrap: 'nowrap', marginLeft: '5%' }}>
      {data &&
        data.map((d, i) => {
          return (
            <Chip
              className="mx-2 my-2"
              key={i}
              label={d}
            />
          );
        })}
    </div>
  }

  return (
    <>
      <Row className='padding-bot-2'>
        {
          props.desc1
            ? <>
              <Col>
                <div className='d-flex'>
                  {
                    <div className='align-items-center'>
                      <div className='font-weight-bold'>
                        {props.desc1}
                      </div>
                      <div className='small'>
                        {props.desc12}
                      </div>
                    </div>
                  }
                  {returnSet(props.value1)}
                </div>
              </Col>
            </>
            : null
        }
        {
          props.desc2 && props.desc2 !== ''
            ? <Col>
              <Row>
                <Col className='font-weight-bold'>{props.desc2}</Col>
                <Col>{props.value2}</Col>
              </Row>
            </Col>
            : props.desc2 === ''
              ? <Col>
                <Row>
                  <Col className='font-weight-bold' />
                  <Col />
                </Row>
              </Col>
              : null
        }
        {
          props.desc3
            ? <Col>
              <Row>
                <Col className='font-weight-bold'>{props.desc3}</Col>
                <Col>{props.value3}</Col>
              </Row>
            </Col>
            : null
        }
      </Row>
    </>
  )
}

export default ServiceActivityDescription;
