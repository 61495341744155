import React from 'react';
import { Col, Row } from 'reactstrap';
import { Chip } from '@mui/material';
import './styles.scss';

const ServiceDescription = (props) => {
  const returnSet = (data) => {
    return <div style={{ flexWrap: 'nowrap', marginLeft: '50px' }}>
      {data &&
        data.map((d, i) => {
          return (
            <Chip
              className="mx-2 my-2"
              key={i}
              label={d}
            />
          );
        })}
    </div>
  }

  const renderSection = () => {
    return (
      <div className='d-flex align-items-center'>
        <span className='font-weight-bold'>
          {props.desc1}
        </span>
        {
          props.desc1 === 'Дейности:' || props.desc1 === 'Специализация по дейности:'
            ? returnSet(props.value1)
            : props.value1
        }
      </div>
    )
  }

  return (
    <>
      <Row className='padding-bot-2'>
        {
          props.desc1
            ? <Col>
              {
                props.desc1 === 'Дейности:' || props.desc1 === 'Специализация по дейности:'
                  ? renderSection()
                  : <Row>
                    <Col className='font-weight-bold'>
                      {props.desc1}
                    </Col>
                    <Col>
                      {
                        props.desc1 === 'Дейности:' || props.desc1 === 'Специализация по дейности:'
                          ? returnSet(props.value1)
                          : props.value1
                      }
                    </Col>
                  </Row>
              }
            </Col>
            : null
        }
        {
          props.desc2 && props.desc2 !== ''
            ? <Col>
              <Row>
                <Col className='font-weight-bold'>{props.desc2}</Col>
                <Col>{props.value2}</Col>
              </Row>
            </Col>
            : props.desc2 === ''
              ? <Col>
                <Row>
                  <Col className='font-weight-bold' />
                  <Col />
                </Row>
              </Col>
              : null
        }
        {
          props.desc3
            ? <Col>
              <Row>
                <Col className='font-weight-bold'>{props.desc3}</Col>
                <Col>{props.value3}</Col>
              </Row>
            </Col>
            : null
        }
      </Row>
    </>
  )
}

export default ServiceDescription;
